import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import MethodologySection from "./methodologySection";
import Foot from "./foot";

function FirstPage() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to allow for the transition to take effect

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <main className="bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800">
        <Navbar />
        <div
          style={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(0)" : "translateY(20px)",
            transition: "opacity 0.6s ease-in-out, transform 0.6s ease-in-out",
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            padding: '8rem 0' // Adjust padding as needed
          }}
        >
          <div className="text-center">
            <h1 className="mb-4 text-5xl font-bold tracking-tight leading-none md:text-6xl xl:text-4xl text-white">
            <span className="text-gray-400"> Welcome to </span>  <span className="xl:text-7xl ms-2">Sotyo AI</span>
            </h1>
            <h1
              className="mb-8 text-3xl font-bold tracking-tight leading-none md:text-4xl xl:text-4xl text-white"
              style={{ lineHeight: "1.5" }}
            >
              Transforming Lung Care <br /> Predicting <span className="text-gray-400">Tomorrow's</span> Health <span className="text-gray-400">Today</span>
            </h1>
            <a
              href="/first"
              className="text-blue-800 bg-white hover:bg-blue-800 hover:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-lg px-10 py-3 md:px-12 transition duration-300"
            >
              Sign Up
            </a>
          </div>
        </div>
      </main>
      <MethodologySection />
    </>
  );
}

export default FirstPage;
