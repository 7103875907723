import React from "react";
import Navbar from "./navbar";

function About() {
  return (
    <main className="flex-1 flex flex-col bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800">
      <Navbar />
      <div className="flex flex-col items-center justify-start flex-1 text-center m-4">
        <div className="w-full max-w-7xl px-8 mt-24">
          <div className="max-w-full mx-auto p-8 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-center">About Us</h2>
            <p className="text-justify">
              At SOTYO, we are dedicated to revolutionizing healthcare diagnostics with the goal of eliminating tuberculosis (TB) by 2030 and advancing cancer screening and early diagnosis. Our AI-powered platform analyzes medical imaging, providing instant, detailed reports for doctors, clinics, hospitals, and labs. Currently, our technology reads chest X-rays, delivering accurate findings for both TB and cancer screening. We aim to expand this capability to CT scans and MRIs, offering comprehensive diagnostic solutions in the near future. In partnership with South India's first TB Hospital AMC, we are driving innovation in medical diagnostics to ensure faster, more accurate healthcare delivery. At SOTYO, we are committed to improving patient outcomes and supporting the global fight against TB and cancer.
            </p>

            <div className="mt-4 flex justify-center">
              <a href="/first"
                className="bg-zinc-900 text-white font-bold py-2 px-4 rounded w-48 text-center"
              >
                Try App
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
