import React from "react";
import heroImage from "../img/method.png"; // Adjust the path as necessary

function MethodologySection() {
  const imageStyle = {
    transition: "transform 0.3s ease",
  };

  const hoverStyle = {
    transform: "scale(1.1)",
  };

  return (
    <main className="bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800 ">
      <div className="max-w-screen-xl px-4 py-16 mx-auto text-center">
        <h2 className="text-3xl font-bold tracking-tight leading-tight dark:text-blue-800 text-white  mb-8"> 
          {/* underline */}
           Our Methodology 
        </h2>
        <div className="flex justify-center">
          <img
            src={heroImage}
            alt="Methodology Illustration"
            style={imageStyle} // Apply the transition style
            className="w-full h-auto"
            onMouseEnter={(e) => e.currentTarget.style.transform = hoverStyle.transform} // Apply zoom on hover
            onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset on mouse leave
          />
        </div>
      </div>
    </main>
  );
}

export default MethodologySection;
