import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import axios from "axios";
import { toast } from "react-toastify"; // Import toast
import Navbar from "./navbar";

function Home({ setPatientAdded }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const generatePatientId = () => {
    return `PAT-${Math.floor(Math.random() * 10000000000).toString().padStart(10, '0')}`; // 10 digits
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const patientId = generatePatientId();
    setIsSubmitting(true);

    try {
      await axios.post('https://sotyo.care/patients/add_new_patient', {
        patient_id: patientId,
        name: name,
        email: email,
        contact_number: contactNumber,
      });

      toast.success("Account Created successfully!"); // Show success toast
      console.log("Navigating to upload-xray with patientId:", patientId); // Debug log
      
      setPatientAdded(true); // Update state here

      navigate('/upload-xray', { state: { patientId } });
    } catch (error) {
      console.error("Error adding patient:", error);
      toast.error("Failed to add patient. Please try again."); // Show error toast
      setError("Failed to add patient. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  const isFormValid = () => {
    return name && email && contactNumber; // Check if all fields are filled
  };

  return (
    <main className="flex-1 flex flex-col bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-fuchsia-800 to-blue-800">
      {/* <Header /> */}
      <Navbar />
      <div className="flex flex-col items-center justify-center flex-1 text-center m-4">
        <div className="w-full max-w-6xl px-4 flex flex-col lg:flex-row">
          <div className="flex-1 p-6">
            <h2 className="text-xl font-bold mb-4 text-left text-white xl:text-2xl">
              Current Lung Disease Predictions
            </h2>
            <ul className="list-disc list-inside text-left text-gray-400 mb-4 ms-3 xl:text-2xl">
              <li>Aortic Enlargement</li>
              <li>Atelectasis</li>
              <li>Calcification</li>
              <li>Cardiomegaly</li>
              <li>Consolidation</li>
              <li>ILD (Interstitial Lung Disease)</li>
              <li>Infiltration</li>
              <li>Lung Opacity</li>
              <li>Nodule/Mass</li>
              <li>Other Lesion</li>
              <li>Pleural Effusion</li>
              <li>Pleural Thickening</li>
              <li>Pneumothorax</li>
              <li>Pulmonary Fibrosis</li>
            </ul>
          </div>
          <div className="w-full max-w-md px-8 items-center justify-center flex-1 mt-20 ">
            <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
              <h2 className="text-xl font-bold mb-4 text-center">
                Enter Patient Details
              </h2>

              {error && <div className="text-red-500 mb-4">{error}</div>}

              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name<span className="text-red-500">*</span>
                    </label>
                  </div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full rounded-md py-2 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    placeholder="Satyam Kumar Singh"
                  />
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email<span className="text-red-500">*</span>
                    </label>
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md py-2 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    placeholder="satyam.sotyoai@example.com"
                  />
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="contactNumber"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contact Number<span className="text-red-500">*</span>
                    </label>
                  </div>
                  <input
                    id="contactNumber"
                    name="contactNumber"
                    type="text"
                    required
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    className="block w-full rounded-md py-2 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    placeholder="99XX45XX12"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={!isFormValid() || isSubmitting}
                    className={`flex w-full justify-center rounded-md bg-zinc-900 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    Next
                  </button>
                </div>
              </form>

              
            </div>
          </div>
          
        </div>
        <p className="text-white mt-4 italic text-left xl:text-xl">Note: This is a Beta Testing Version.</p>
      </div>
    </main>
  );
}

export default Home;
